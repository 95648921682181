<template>
  <div id="app">
    <WebcamPlayer url="https://webcam.sanvitometeo.it/cam-00/index.m3u8" />
  </div>
</template>

<script>
import WebcamPlayer from "@/components/WebcamPlayer";

export default {
  name: "App",
  components: {
    WebcamPlayer,
  },
};
</script>

<style lang="scss">
html,
body,
div#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #2c3e50;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
