import Vue from "vue";
import App from "./App.vue";
import VideoPlayer from "vue-video-player";

import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";

Vue.config.productionTip = false;

Vue.use(VideoPlayer);

const hls = require("videojs-contrib-hls");
Vue.use(hls);

new Vue({
  render: (h) => h(App),
}).$mount("#app");
