<template>
  <div class="webcam-container">
    <div class="webcam-player">
      <video-player :options="playerOptions" />
    </div>
  </div>
</template>

<script>
export default {
  name: "WebcamPlayer",
  props: {
    url: String,
  },
  data() {
    return {
      playerOptions: {
        autoplay: true,
        muted: false,
        loop: false,
        preload: "auto",
        aspectRatio: "16:9",
        fluid: true,
        sources: [
          {
            type: "application/x-mpegURL",
            src: this.url,
          },
        ],
        hls: true,
        notSupportedMessage:
          "This video cannot be played for the time being. Please try again later.",
        controlBar: {
          timeDivider: false,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true,
        },
      },
    };
  },
};
</script>

<style scoped lang="scss">
div.webcam-container {
  width: 100%;
  height: 100%;
  line-height: 1000px;
}

div.webcam-player {
  width: 80%;
  max-height: 100%;
  margin: 30px auto;
}
</style>
